/* ============
 * Getters for the account module
 * ============
 *
 * The getters that are available on the
 * account module.
 */

import {
  G,
  not,
  eql,
  invoke,
  compose,
  firstOf,
  identity,
  all
} from '@2l/utils'

const getAccessMode = firstOf(G('materialViewMode'), identity('published'))

export default {
  isPremium: eql('subscription', 'premium'),
  getScore: G('score'),
  getOnboardingVersion: firstOf(
    G('settings.onboardingVersion'),
    identity('v3')
  ),
  getCurrentLanguage: firstOf(
    G('lang'),
    identity(document.documentElement.lang)
  ),
  isEditor: eql('materialAccess', 'all'),
  isUnpublishedAccessMode: eql(getAccessMode, 'unpublished'),
  isPublishedAccessMode: eql(getAccessMode, 'published'),
  isAllAccessMode: eql(getAccessMode, 'all'),
  isOnboardingFinished: eql(G('onboardingFinished'), true),
  registerAsAnonymous: G('registerAsAnonymous'),
  getEmail: G('email'),
  getBill: G('bill'),
  getBillId: G('bill._id'),
  getDuration: G('bill.subscriptionPlan.duration'),
  getDurationKey: G('bill.subscriptionPlan.durationKey'),
  getCurrency: G('bill._currency'),
  getPrice: G('bill._price'),
  getUnit: compose(G('bill.subscriptionPlan.unit'), invoke('toLowerCase')),
  getPeriod: G('bill.subscriptionPlan.period'),
  getStartDate: G('bill.startDate'),
  getEndDate: G('bill.endDate'),
  isRenewable: G('bill.renewable'),
  isAcknowledged: G('bill.acknowledged'),
  isBillPending: all(not(G('bill.acknowledged')), G('bill')),
  getPlanType: G('bill.subscriptionPlan.type'),

  getNavigationCourseId: G('settings.navigation.courseId'),
  getNavigationSection: G('settings.navigation.section'),
  getRemoteSubscriptions: G('settings.subscriptionsWeb'),
  isNavigationToCourse: eql('settings.navigation.section', 'courses'),
  isNavigationToLibrary: eql('settings.navigation.section', 'library'),

  registerBySocNet: G('registerBySocNet'),
  hasAcceptedAccounts: G('hasAcceptedAccounts'),

  getUserId: G('_id'),
  isAuthenticated: not(not(G('_id'))),

  getName: compose(G('settings.name'), invoke('trim')),
  getAvatar: G('settings.avatar'),
  getActiveProfile: G('activeProfile'),
  getUserCountry: G('userCountryCode'),
  getLanguageLevel: G('languageLevel'),

  getCreateDate: G('createDate'),
  isRoadMapAllowed: G('roadMapAllowed'),
  getHasInstallSigns: G('hasInstallSigns'),
  isCoursesUser: G('coursesUser'),
  areCoursesAvailable: G('coursesAvailable'),
  hasCompletedLessons: G('hasCompletedLessons'),
  isVisiblePremiumPromo: G('isVisiblePremiumPromo'),

  getCurrentDate: () => new Date()
}
