/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import { setI18nLanguage } from '@/plugins/vue-i18n'

import {
  FIND,
  SET_LANG,
  UPDATE_LANG,
  UPDATE_PROFILE,
  UPDATE_EMAIL,
  UPDATE_SETTINGS,
  UNSUBSCRIBE_SUCCESS,
  SET_USER_LOCATION,
  SET_VISIBLE_PREMIUM_PROMO
} from './mutation-types'

export default {
  [SET_LANG] (state, lang) {
    state.lang = lang

    localStorage.setItem('ewaLanguage', lang)
  },
  [FIND] (state, account) {
    state._id = account._id
    state.email = account.email
    state.login = account.login
    state.score = account.score
    state.lastName = account.lastName
    state.firstName = account.firstName
    state.subscription = account.subscription
    state.materialAccess = account.materialAccess
    state.languageLevel = account.languageLevel

    state.bill = account.bill
    state.settings = account.settings
    state.onboardingFinished = account.onboardingFinished
    state.registerAsAnonymous = account.registerAsAnonymous
    state.registerBySocNet = account.registerBySocNet
    state.hasAcceptedAccounts = account.hasAcceptedAccounts

    state.learningMaterials = account.learningMaterials
    state.lang = account.lang || getDefaultLanguage()
    state.activeProfile = account.activeProfile

    state.createDate = account.createDate
    state.profiles = account.profiles
    state.coursesUser = account.coursesUser
    state.roadMapAllowed = account.roadMapAllowed
    state.coursesAvailable = account.coursesAvailable
    state.hasCompletedLessons = account.hasCompletedLessons
    state.hasInstallSigns = account.hasInstallSigns

    setI18nLanguage(state.lang)
  },
  [UPDATE_LANG] (state, lang) {
    const storedLang = localStorage.getItem('ewaLanguage')

    state.lang = storedLang || lang || getDefaultLanguage()
    setI18nLanguage(state.lang)
  },
  [UPDATE_PROFILE] (state, lang) {
    state.activeProfile = lang

    setI18nLanguage(state.lang, state.activeProfile)
  },
  [UPDATE_EMAIL] (state, email) {
    state.email = email
  },
  [UPDATE_SETTINGS] (state, settings) {
    state.settings = settings
  },
  [UNSUBSCRIBE_SUCCESS] (state) {
    state.unsubscribed = true
  },
  [SET_USER_LOCATION] (state, code) {
    state.userCountryCode = code
  },
  [SET_VISIBLE_PREMIUM_PROMO] (state, payload) {
    state.isVisiblePremiumPromo = payload
  }
}

function getDefaultLanguage () {
  if (window.location.host.split('.').pop() === 'ru') return 'ru'
  if (window.navigator.language) { return window.navigator.language.slice(0, 2) }
}
